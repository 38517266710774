<template>
  <b-container>
    <b-card>
      <h1 class="mb-4">
        Welcome to the Admin Portal of the IFAC Database!
      </h1>
      <p>From here you should find tools to</p>
      <ul>
        <li>Administer access rights to the Admin portal</li>
        <li>Consult, approve, update IFAC Affiliates</li>
        <li>Consult, administer, update IFAC Conferences from application to final report.</li>
      </ul>
      <p>
        If you need assistance regarding please contact the IFAC Secretariat <a
          class="text-decoration-none"
          href="mailto:secretariat@ifac-control.org">secretariat@ifac-control.org</a>.
      </p>
    </b-card>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      user: 'auth/user',
    }),
  },
};
</script>

<style>
</style>
